var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "material-card",
    {
      staticClass: "mx-4",
      attrs: { color: "primary", title: "Detalle del pedido" },
    },
    [
      _c(
        "v-card-title",
        [
          _c("v-text-field", {
            attrs: { label: "Buscar", "hide-details": "" },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          "rows-per-page-text": "Registros por página",
          headers: _vm.headers,
          items: _vm.itemsData,
          "rows-per-page-items": _vm.rowsPerPageItems,
        },
        scopedSlots: _vm._u([
          {
            key: "headerCell",
            fn: function ({ header }) {
              return [
                _c("span", {
                  staticClass: "subheading font-weight-light text-primary",
                  domProps: { textContent: _vm._s(header.text) },
                }),
              ]
            },
          },
          {
            key: "items",
            fn: function ({ item }) {
              return [
                _c("td", { staticClass: "text-xs-left" }, [
                  _vm._v(_vm._s(item.orderNumber)),
                ]),
                _c("td", { staticClass: "text-xs-left" }, [
                  _vm._v(_vm._s(item.pluCode)),
                ]),
                _c("td", { staticClass: "text-xs-left" }, [
                  _vm._v(_vm._s(item.description)),
                ]),
                _c("td", { staticClass: "text-xs-left" }, [
                  _vm._v(_vm._s(item.quantity)),
                ]),
                _c(
                  "td",
                  [
                    _c(
                      "v-edit-dialog",
                      {
                        attrs: {
                          "return-value": item.deliveredQuantity,
                          large: "",
                          lazy: "",
                          persistent: "",
                          "cancel-text": "Cancelar",
                          "save-text": "Guardar",
                        },
                        on: {
                          "update:returnValue": function ($event) {
                            return _vm.$set(item, "deliveredQuantity", $event)
                          },
                          "update:return-value": function ($event) {
                            return _vm.$set(item, "deliveredQuantity", $event)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("number-delivered"),
                                      "single-line": "",
                                      type: "number",
                                    },
                                    model: {
                                      value: item.deliveredQuantity,
                                      callback: function ($$v) {
                                        _vm.$set(item, "deliveredQuantity", $$v)
                                      },
                                      expression: "item.deliveredQuantity",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              (item.deliveredQuantity =
                                _vm.getValidDeliveredCount(
                                  item.deliveredQuantity,
                                  item.quantity
                                ))
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("td", { staticClass: "text-xs-left" }, [
                  _vm._v(_vm._s(item.quantity - item.deliveredQuantity)),
                ]),
                _c(
                  "td",
                  [
                    _c(
                      "v-edit-dialog",
                      {
                        attrs: {
                          "return-value": item.reason,
                          large: "",
                          lazy: "",
                          persistent: "",
                          "cancel-text": "Cancelar",
                          "save-text": "Guardar",
                        },
                        on: {
                          "update:returnValue": function ($event) {
                            return _vm.$set(item, "reason", $event)
                          },
                          "update:return-value": function ($event) {
                            return _vm.$set(item, "reason", $event)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("v-select", {
                                    attrs: {
                                      disabled: (item.disablePendingReason =
                                        item.quantity -
                                          item.deliveredQuantity <=
                                        0),
                                      "item-value": "id",
                                      "item-text": "name",
                                      items: _vm.pendingReasonItems,
                                      placeholder: "Seleccione un motivo...",
                                    },
                                    model: {
                                      value: item.reason,
                                      callback: function ($$v) {
                                        _vm.$set(item, "reason", $$v)
                                      },
                                      expression: "item.reason",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.getPendingReasonName(
                                !item.disablePendingReason ? item.reason : ""
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  !item.disablePendingReason === false &&
                                  !item.reason === true
                                    ? _c(
                                        "span",
                                        _vm._g({}, on),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "error",
                                              },
                                            },
                                            [_vm._v("mdi-alert-circle")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v("Por favor, seleccione un motivo!")])]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { staticClass: "text-xs-center" },
            [
              _c(
                "v-btn",
                {
                  attrs: { round: "", color: "success" },
                  on: { click: _vm.updatePending },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("save-button-label")) +
                      "\n        "
                  ),
                  _c("v-icon", { attrs: { dark: "", left: "" } }, [
                    _vm._v("mdi-upd"),
                  ]),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "290" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              { attrs: { color: "red", round: "", dark: "" } },
                              on
                            ),
                            [_vm._v(_vm._s(_vm.$t("cancel")))]
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "headline" }, [
                        _vm._v(
                          _vm._s(_vm.$t("pending.alert-close-cancel-pending"))
                        ),
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "green darken-1", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = false
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("not")))]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "green darken-1", flat: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closePending()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("yes")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }