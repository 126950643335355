export default {
  data() {
    return {
      dialog: false,
      pendingReasonItems: [],
      disablePendingReason: false,
      search: "",
      headers: [
        {
          text: "#Orden",
          sortable: false,
          value: "numOrder"
        },
        {
          text: "PLU",
          sortable: false,
          value: "plu"
        },
        {
          text: "Descripción",
          value: "description",
          sortable: false
        },
        {
          text: "Cantidad",
          value: "count",
          sortable: false
        },
        {
          text: "Cantidad de entregados",
          value: "numberDelivered",
          sortable: false
        },
        {
          text: "Cantidad de pendientes",
          value: "amountEarrings",
          sortable: false
        },
        { text: "Motivo", value: "reason", sortable: false }
      ],
      itemsData: [],
      rowsPerPageItems: [5, 10, 15, 20]
    };
  },
  props: ["medicineList", "orderId"],
  watch: {
    medicineList() {
      this.itemsData = this.medicineList;
    }
  },
  mounted() {
    this.getPendingReason();
  },
  methods: {
    onRowClick(item) {
      this.$emit("row-click", item);
    },
    getValidDeliveredCount(a, b) {
      return a <= b ? a : b;
    },
    getPendingReason() {
      PendingReasonsService.getPendings().then(response => {
        this.pendingReasonItems = response.data;
      });
    },
    getPendingReasonName(id) {
      console.log("id", id);
      let pendingReasonItem = this.pendingReasonItems.find(
        item => item.id === id
      );
      return pendingReasonItem ? pendingReasonItem.name : "";
    },
    updatePending() {
      this.$emit("close-pending", false);
    },
    closePending() {
      this.dialog = false;
      this.itemsData = [];
      this.$emit("close-pending", false);
    }
  }
};
